<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="worksettings">
        <div class="con">
            <div class="tit">
                <p class="p1">工作日设置</p>
            </div>
            <ul>
                <li v-for="(i,index) in xingqi_list" @click="i.zhi=!i.zhi" :key="index">
                    <img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                    {{i.name}}
                </li>
            </ul>
            <p class="tishi">提示：员工轮休采取轮休制，设置周一到周日均为工作日</p>
            <div class="queren">
                <p></p>
                <span @click="dianji_queding">确认</span>
            </div>
        </div>
    </div>
</template>

<script>
import { update_workday, query_workday } from '../../api/api.js'
export default {
  name: 'worksettings',
  data () {
    return {
      xingqi_list: [
        {
          name: '周一',
          zhi: true,
          id: '1'
        },
        {
          name: '周二',
          zhi: true,
          id: '2'
        },
        {
          name: '周三',
          zhi: true,
          id: '3'
        },
        {
          name: '周四',
          zhi: true,
          id: '4'
        },
        {
          name: '周五',
          zhi: true,
          id: '5'
        },
        {
          name: '周六',
          zhi: false,
          id: '6'
        },
        {
          name: '周日',
          zhi: false,
          id: '7'
        }
      ]
    }
  },
  created () {
  },
  watch: {
      
  },
  mounted () {
    this.jichu()
  },
  methods: {
    // 基础信息
    jichu () {
      query_workday({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date != undefined) {
            const list = date.workday.split(',')
            this.xingqi_list.forEach(item => {
              item.zhi = false
            })
            list.forEach(item => {
              this.xingqi_list.forEach(it => {
                if (item == it.id) {
                  it.zhi = true
                }
              })
            })
          }
        } else if (res.data.code == 500) {}
      })
    },
    dianji_queding () {
      const list = []
      this.xingqi_list.forEach(item => {
        if (item.zhi) {
          list.push(item)
        }
      })
      if (list.length != 0) {
        update_workday({
          data: {
            ent_id: this.$ent_id(),
            workday: list.map(it => it.id).join(',')
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '设置成功',
              type: 'success'
            })
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请选择工作日',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.worksettings {
    height:100%;
    width:100%;
    .con{
        padding: 0.33rem;
        .tit{
            display: flex;
            align-items: center;
            .p1{
                font-size: 0.18rem;
                color:#1A2533;
            }
            .p2{
                margin-left: 0.24rem;
                font-size: 0.14rem;
                color:#888888;
            }
            margin-bottom: 0.63rem;
        }
        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            li{
                img{
                    height:0.16rem;
                    width:0.16rem;
                    margin-right: 0.05rem;
                }
                font-size: 0.12rem;
                display: flex;
                align-items: center;
                padding: 0.1rem;
                cursor: pointer;
            }
            
        }
        .tishi{
            font-size: 0.1rem;
            color:rgb(249, 108, 108);
            text-align: center;
        }
        .queren{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0.3rem;
            span{
                display: inline-block;
                font-size: 0.16rem;
                color:#fff;
                padding: 0.08rem 0.1rem;
                border-radius: 0.03rem;
                background: #9a86db;
                cursor: pointer;
            }
        }
    }
}
</style>
